@import './palette';
@import './theme';
@import './utils';

$base: (
    step-future-fuscia: palette-var('future-fuscia'),
    step-cyanora: palette-var('cyanora'),
    step-coal-mine-canary: palette-var('coal-mine-canary'),
    step-future-fuscia-down: palette-var('future-fuscia-down'),
    step-cyanora-down: palette-var('cyanora-down'),
    step-coal-mine-canary-down: palette-var('coal-mine-canary-down'),
    step-midnight: palette-var('midnight'),
    step-black: palette-var('black'),
    step-black-v2: palette-var('black-v2'),
    step-dark-gray: palette-var('dark-gray'),
    step-white: palette-var('white'),
    step-off-white: palette-var('off-white'),
    step-before-midnight: palette-var('before-midnight'),
    step-right-before-midnight: palette-var('right-before-midnight'),
    step-cold-steel: palette-var('cold-steel'),
    step-amethyst: palette-var('amethyst'),
    step-linear-gradient-cyanora-to-future-fuscia:
        palette-var('linear-gradient-cyanora-to-future-fuscia'),
    step-vertical-gradient-cyanora-to-future-fuscia:
        palette-var('vertical-gradient-cyanora-to-future-fuscia'),
    step-diagonal-gradient-midnight-haze-to-black:
        palette-var('diagonal-gradient-midnight-haze-to-black'),
    step-almost-black: palette-var('almost-black'),
    step-right-before-black: palette-var('right-before-black'),
    // backward compatibility
    step-warning: theme-var('warning'),
    step-negative: theme-var('negative'),
    step-positive: theme-var('positive'),
    step-dark-text: theme-var('text'),
    step-light-text: theme-var('inverse-text'),
    step-secondary-text: theme-var('secondary-text'),
);

$interactive: (
    step-cyanora: (
        border: palette-var('white'),
        active: palette-var('cyanora-down'),
        text: palette-var('off-white'),
    ),
    step-future-fuscia: (
        border: palette-var('white'),
        active: palette-var('future-fuscia-down'),
        text: palette-var('off-white'),
        theme: 'future-fuscia',
    ),
    step-off-white: (
        border: palette-var('white'),
        active: palette-var('off-white'),
        text: palette-var('midnight'),
        theme: 'off',
    ),
    step-white: (
        border: palette-var('black-v2'),
        active: palette-var('off-white'),
        text: palette-var('black-v2'),
    ),
    step-black: (
        border: palette-var('black'),
        active: palette-var('black'),
        text: palette-var('white'),
    ),
    step-black-v2: (
        border: palette-var('black-v2'),
        active: palette-var('black-v2'),
        text: palette-var('white'),
    ),
    step-right-before-black: (
        border: palette-var('right-before-black'),
        active: palette-var('right-before-black'),
        text: palette-var('white'),
    ),
    step-almost-black: (
        border: palette-var('almost-black'),
        active: palette-var('almost-black'),
        text: palette-var('white'),
    ),
    step-midnight: (
        border: palette-var('before-midnight'),
        active: palette-var('right-before-midnight'),
        text: palette-var('off-white'),
        theme: 'dark',
    ),
    step-right-before-midnight: (
        border: palette-var('before-midnight'),
        active: palette-var('midnight'),
        text: palette-var('off-white'),
        theme: 'dark',
    ),
    step-linear-gradient-cyanora-to-future-fuscia: (
        border: palette-var('white'),
        active: palette-var('linear-gradient-cyanora-to-future-fuscia'),
        text: palette-var('off-white'),
    ),
    step-vertical-gradient-cyanora-to-future-fuscia: (
        border: palette-var('white'),
        active: palette-var('vertical-gradient-cyanora-to-future-fuscia'),
        text: palette-var('off-white'),
    ),
    step-diagonal-gradient-midnight-haze-to-black: (
        border: palette-var('black'),
        active: palette-var('diagonal-gradient-midnight-haze-to-black'),
        text: palette-var('white'),
    ),
);

$brand: (
    'amethyst': #c772fd,
    'apricot': #ff8f28,
    'black': #000,
    'black-v2': #111,
    'dark-gray': #222,
    'coal-mine-canary': #fcd411,
    'cold-steel': #afb4c2,
    'cyanora': #3cd0f5,
    'future-fuscia': #f66299,
    'springtime': #64da90,
);

$utility: (
    'before-midnight': #49495d,
    'cold-steel': #afb4c2,
    'midnight': #222234,
    'past-midnight': #0f0f17,
    'off-white': #f6f7fa,
    'right-before-midnight': #363649,
    'white': #fff,
);

$aliases: (
    'text': map-get($utility, 'white'),
    'text-dim': map-get($utility, 'coldSteel'),
    'background': map-get($utility, 'midnight'),
    'background-lite': map-get($utility, 'beforeMidnight'),
);

$colors: map-collect($base);

@mixin set-color-variables {
    @each $key, $value in $colors {
        --#{$key}: #{$value};
    }
}

@function get-color($color-key) {
    @return map-get($colors, $color-key);
}

/** Returns styles for hovering this element */
@mixin hoverme($scale: 1.06) {
    cursor: pointer;
    transform: scale($scale);
    transform-origin: center;
    transition: all 0.1s ease-out;
}

/** Returns acceptable color from palette for pressing this element */
@function pressme($color) {
    @return map-get(map-get($interactive, $color), 'active');
}

/** Returns acceptable text color from palette for this background */
@function text-for-background($color) {
    @return map-get(map-get($interactive, $color), 'text');
}

/** Returns acceptable border color from palette for this background */
@function border-for-background($color) {
    @return map-get(map-get($interactive, $color), 'border');
}

/** Adds ability to vary text colors for the stylesheet */
@mixin text-color-variability {
    @each $key, $value in $colors {
        .#{$key} {
            color: var(--#{$key});
        }
    }
}

/** Adds ability to vary background colors for the stylesheet */
@mixin background-color-variability(
    $with-text: false,
    $with-interactivity: false
) {
    @each $key, $value in $colors {
        .background-#{$key} {
            background: var(--#{$key});

            @if map-has-key($interactive, $key) {
                @if $with-text {
                    color: text-for-background($key);
                }

                @if $with-interactivity {
                    &:hover,
                    &:focus {
                        @include hoverme;
                    }

                    &:active {
                        background: pressme($key);
                    }
                }
            }
        }
    }
}

/** Adds ability to vary fill colors for the stylesheet, useful for SVG */
@mixin fill-color-variability($with-interactivity: false) {
    .fill-currentColor {
        fill: currentColor;
    }

    @each $key, $value in $colors {
        .fill-#{$key} {
            fill: var(--#{$key});

            @if map-has-key($interactive, $key) and $with-interactivity {
                &:hover,
                &:focus {
                    @include hoverme;
                }

                &:active {
                    fill: pressme($key);
                }
            }
        }
    }
}

/** Adds ability to vary stroke colors for the stylesheet, useful for SVG */
@mixin stroke-color-variability {
    @each $key, $value in $colors {
        .stroke-#{$key} {
            stroke: var(--#{$key});
        }
    }
}
